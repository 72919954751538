.timeline {
    display: flex;
    height: 55px;
    width: 100%;
    background-color: white;
    position: relative;
}

.timeline div {
    width: 10px;
    height: 55px;
    border-radius: 5%;
    position: absolute;
    left: 0;
}

.timeline-container::-webkit-scrollbar {
    width: 5px; /* slim the scrollbar */
    height: 5px;
  }
  
  .timeline-container::-webkit-scrollbar-thumb {
    background-color: #ccc; /* change the scrollbar color */
    border-radius: 5px;
  }
  
  .timeline-container::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* change the scrollbar track color */
  }

.timeline-container {
    overflow-x: auto;
    width: 100%; /* or a fixed width, depending on your design */
    padding-bottom: 10px; /* add some padding to accommodate the scrollbar */
    white-space: nowrap; /* prevent the timeline from wrapping to a new line */
    scrollbar-width: thin; 
    scrollbar-color: #ccc; 

  }

.time-label {
    text-align: left;
    font-weight: bold;
  }  

.timeline .fail {
    background-color: rgb(226, 13, 13);
}

.timeline .success {
    background-color: rgb(133, 233, 133);
}

.fail {
    background-color: rgb(226, 13, 13);
}

.run-fail {
    background-color: rgb(151, 151, 151);
}

.success {
    background-color: rgb(133, 233, 133);
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    max-width: 1000px;
    padding: 5px;
    white-space: normal;
    overflow-wrap: break-word;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    bottom: -20%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
  opacity: 1;
  transition: opacity 0.3s;
  font-size: 12px;
  line-height: 1.1;
  
  
}
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    
  }

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}