.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Adjust column width as needed */
    gap: 10px; /* Adjust gap between items */
    padding: 20px;
    max-height: 20em;
    overflow: auto;
  }
  
  .grid-item {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  