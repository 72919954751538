@keyframes slideInFromRight {
    from {
      right: 0;
      opacity: 0;
    }
    to {
      right: 50%;
      opacity: 1;
    }
  }
  