/* Styles for the modal */
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    min-width: 90%; /* changed to percentage */
    max-width: 90em; /* added max-width for larger screens */
    max-height :90%
}

/* Styles for the overlay */
.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: 'blur(8px)',
}